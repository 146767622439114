import React from 'react';
import { Formik } from 'formik';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { flow } from 'lodash';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';

import FieldCurrencyComponent, {
  FieldCurrencyComponentDigits,
} from '@giro/shared-components/Fields/FieldCurrency.component';
import FieldLabelFormikComponent from '@giro-pdv-whitelabel/components/FieldLabelFormik.component';
import FieldDocumentComponent from '@giro/shared-components/Fields/FieldDocument.component';
import TextFieldLabelComponent from '@giro-pdv-whitelabel/components/TextFieldLabel.component';
import UpdateAmountComponent from '../components/UpdateAmount.component';
import FormPlacaRenavamComponent from '../components/Forms/FormPlacaRenavam.component';
import FormPlateComponent from '../components/Forms/FormPlaca.component';
import FormPlacaDocumentComponent from '../components/Forms/FormPlacaDocument.component';
import FormRenavamComponent from '../components/Forms/FormRenavam.component';
import BoxDebitComponent from '../components/BoxDebit.component';
import DialogSelectAllDebitsComponent from '../components/DialogSelectAllDebits.component';
import ButtonPayActionsComponent from '../components/ButtonPayActions.component';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import PDVLayout from '../layouts/PDV.layout';

import dialogSendPayment from '../store/dialogs/dialogSendPayment';
import dialogPay from '../store/dialogs/dialogPay';
import tablePayment from '../store/tablePayment';
import transactions from '../store/transactions';
import domain from '../store/debts/domain';
import debts from '../store/debts/debts';

const TYPES = {
  PLACARENAVAM: 0,
  RENAVAM: 1,
  DOCUMENTO: 2,
  PLATE: 5,
};

const FormDetran = ({ setModalSelectAll }) => {
  const formikRef: any = React.useRef();
  const dispatch = useDispatch();
  const { user } = useAuthHook();

  const dispatchRedux = {
    serviceGet: flow(domain.action.serviceGet, dispatch),
    resetDebts: flow(debts.action.resetState, dispatch),
    updateFilters: flow(debts.action.updateFilters, dispatch),
    OPEN_PAYMENT: flow(dialogSendPayment.action.open, dispatch),
    OPEN_PAY: flow(dialogPay.action.open, dispatch),
    transactionsServicePost: flow(transactions.action.servicePost, dispatch),
    updateReturn: flow(tablePayment.action.updateReturn, dispatch),
    updateTypeTax: flow(tablePayment.action.updateTypeTax, dispatch),
    select: flow(debts.action.select, dispatch),
    unselect: flow(debts.action.unselect, dispatch),
    selectAll: flow(debts.action.selectAll, dispatch),
    unselectAll: flow(debts.action.unselectAll, dispatch),
  };

  const selectorRedux = {
    selectedDebts: useSelector(debts.selector.selectSelected),
    dataDebts: useSelector(debts.selector.selectData),
    domainData: useSelector(domain.selector.selectData),
    return: useSelector(tablePayment.selector.selectReturn),
    installmentSelected: useSelector(
      tablePayment.selector.selectInstallmentSelected
    ),
    transactionsLoading: useSelector(transactions.selector.selectLoading),
    loadingDebts: useSelector(debts.selector.selectLoading),
    errorDebts: useSelector(debts.selector.selectError),
  };

  const fetchedDebts =
    selectorRedux.loadingDebts === false && selectorRedux.errorDebts === false;

  const sumSelectedDebts = selectorRedux.selectedDebts?.reduce((acc, curr) => {
    return acc + curr.amount;
  }, 0);

  const indeterminate =
    selectorRedux?.selectedDebts?.length !==
      selectorRedux?.dataDebts?.results?.length &&
    selectorRedux?.selectedDebts?.length !== 0;

  React.useEffect(() => {
    dispatchRedux.serviceGet();

    return () => {
      dispatchRedux.resetDebts();
    };
  }, []);

  const onlyDetrans = selectorRedux.domainData?.filter(
    (a) => a.status === 'ACTIVE' && ![3, 4].some((s) => s === a.filter_type)
  );

  const initialValues = {
    integration_id: '2a007d0e-33b0-4cf8-aecd-e886cb48b139',
    total: 0,
    total_base: 0,
    fees: 0,
    segment: '7',
    type_tax: 'client',
  };

  const onSubmit = () => {};

  const handleGetAppByIntegrationId = (integration_id) =>
    selectorRedux.domainData?.find((dd) => dd.id === integration_id);

  const handleIsValidByFilterType = (filter_type, values) => {
    if (filter_type === TYPES.DOCUMENTO) {
      return !values?.placa || !values?.document_number;
    }

    if (filter_type === TYPES.PLACARENAVAM) {
      return !values?.placa || !values?.renavam;
    }

    if (filter_type === TYPES.RENAVAM) {
      return !values?.renavam;
    }

    if (filter_type === TYPES.PLATE) {
      return !values?.placa;
    }
  };

  React.useEffect(() => {
    formikRef.current.setFieldValue(
      'total',
      sumSelectedDebts + Number(formikRef?.current?.values?.fees)
    );
    formikRef.current.setFieldValue('total_base', sumSelectedDebts);
  }, [selectorRedux.selectedDebts]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, setValues }) => {
        return (
          <>
            <UpdateAmountComponent />
            <Box display="flex" flexDirection="column" gap={4}>
              <Box display="flex" flexDirection="column" gap={4}>
                <FieldLabelFormikComponent
                  name="integration_id"
                  label="Detran"
                  select
                  size="small"
                  disabled={fetchedDebts}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      integration_id: e.target.value as any,
                      total: 0,
                      total_base: 0,
                      fees: 0,
                    });
                  }}
                >
                  {onlyDetrans?.map((od) => (
                    <MenuItem value={od.id} key={`${od.id}-select`}>
                      {od?.description}
                    </MenuItem>
                  ))}
                </FieldLabelFormikComponent>

                {handleGetAppByIntegrationId(values?.integration_id)
                  ?.filter_type === TYPES.PLACARENAVAM && (
                  <FormPlacaRenavamComponent disabled={fetchedDebts} />
                )}

                {handleGetAppByIntegrationId(values?.integration_id)
                  ?.filter_type === TYPES.DOCUMENTO && (
                  <FormPlacaDocumentComponent disabled={fetchedDebts} />
                )}

                {handleGetAppByIntegrationId(values?.integration_id)
                  ?.filter_type === TYPES.RENAVAM && (
                  <FormRenavamComponent
                    key={`${values?.integration_id}-renavam`}
                    disabled={fetchedDebts}
                  />
                )}

                {/* {handleGetAppByIntegrationId(values?.integration_id)
                  ?.filter_type === TYPES.RENAVAM && (
                  <FormPlateComponent
                    key={`${values?.integration_id}-plate`}
                    disabled={fetchedDebts}
                  />
                )} */}

                {/* <FieldCurrencyComponentDigits
                  customInput={TextFieldLabelComponent}
                  size="small"
                  name="amount"
                  label="Valor"
                  onChange={(val) => setFieldValue('total', Number(val))}
                /> */}

                {fetchedDebts && (
                  <Box display="flex" flexDirection="column">
                    {(selectorRedux.dataDebts?.results?.[0]?.owner ||
                      selectorRedux.dataDebts?.results?.[0]?.plate) && (
                      <Box display="flex" flexDirection={'column'} mb={3}>
                        {selectorRedux.dataDebts?.results?.[0]?.owner && (
                          <Typography variant="subtitle1">
                            Proprietário:{' '}
                            {selectorRedux.dataDebts?.results?.[0]?.owner}
                          </Typography>
                        )}
                        {selectorRedux.dataDebts?.results?.[0]?.plate && (
                          <Typography variant="subtitle1">
                            Placa:{' '}
                            {selectorRedux.dataDebts?.results?.[0]?.plate}
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Box display="flex" alignItems="center" gap={1}>
                      <Checkbox
                        sx={{ ml: '21px' }}
                        indeterminate={indeterminate}
                        color="secondary"
                        checked={
                          selectorRedux?.selectedDebts?.length ===
                          selectorRedux?.dataDebts?.results?.length
                        }
                        onChange={(ev, checked) => {
                          if (indeterminate || !checked) {
                            return dispatchRedux.unselectAll();
                          }

                          if (checked) {
                            return dispatchRedux.selectAll();
                          }

                          // console.log(ev);
                        }}
                      />
                      <Typography variant="subtitle1">
                        Total selecionado:{' '}
                        {Number(sumSelectedDebts).toLocaleString('pt-br', {
                          currency: 'BRL',
                          style: 'currency',
                        })}
                      </Typography>

                      {/* <Button onClick={() => dispatchRedux.selectAll()}>
                        Selecionar todos
                      </Button>
                      <Button onClick={() => dispatchRedux.unselectAll()}>
                        Limpar seleções
                      </Button> */}
                    </Box>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(2, 1fr)"
                      gap={2}
                      // height={300}
                      // sx={{ overflowY: 'scroll' }}
                      bgcolor="gray.100"
                      p={2}
                      borderRadius={1}
                    >
                      {selectorRedux.dataDebts?.results?.map((a, index) => (
                        <BoxDebitComponent
                          name={a.description}
                          key={`${a.reference}-${index}`}
                          // plate={a.plate}
                          // owner={a.owner}
                          type={a.type}
                          checked={
                            !!selectorRedux.selectedDebts?.find(
                              (s) => s.reference === a.reference
                            )
                          }
                          amount={a.amount}
                          onChangeCheck={(ev, value) => {
                            const isLIC = a?.type === 'LIC';

                            if (isLIC) {
                              setModalSelectAll(true);

                              return true;
                            }

                            if (value) {
                              dispatchRedux.select(a);
                            }

                            if (!value) {
                              dispatchRedux.unselect(a);
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                )}

                {fetchedDebts && (
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                    <FieldCurrencyComponentDigits
                      customInput={TextFieldLabelComponent}
                      size="small"
                      label="Honorários"
                      disabled={!selectorRedux.dataDebts?.results?.[0]?.owner}
                      name="fees"
                      onChange={(val) =>
                        setFieldValue(
                          'total',
                          Number(values.total_base) + Number(val)
                        )
                      }
                    />
                    {values.type_tax === 'client' && (
                      <Box display="flex" flexDirection="column">
                        <Typography variant="trasso_heading_small">
                          R
                        </Typography>
                        <Slider
                          disabled={
                            !selectorRedux.dataDebts?.results?.[0]?.owner
                          }
                          step={2}
                          sx={{
                            mt: 4,
                            '& .MuiSlider-track': {
                              border: 'none',
                              backgroundColor: 'trasso.gray.40',
                            },
                            '& .MuiSlider-thumb': {
                              height: 24,
                              width: 24,
                              backgroundColor: '#fff',
                              border: '4px solid currentColor',
                            },
                            '& .MuiSlider-valueLabel': {
                              lineHeight: 1.2,
                              fontSize: 12,
                              background: 'unset',
                              padding: 0,
                              width: 32,
                              height: 32,
                              backgroundColor: 'trasso.purple.60',
                            },
                          }}
                          color="secondary"
                          size="small"
                          defaultValue={0}
                          aria-label="Small"
                          valueLabelDisplay="auto"
                          max={12}
                          value={selectorRedux.return}
                          onChange={(ev, value) => {
                            dispatchRedux.updateReturn(value);
                          }}
                          marks={[
                            {
                              value: 0,
                              label: (
                                <Typography
                                  variant="trasso_body_small"
                                  color="trasso.purple.100"
                                >
                                  0
                                </Typography>
                              ),
                            },
                            {
                              value: 12,
                              label: (
                                <Typography
                                  variant="trasso_body_small"
                                  color="trasso.purple.100"
                                >
                                  12
                                </Typography>
                              ),
                            },
                          ]}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>

              {!fetchedDebts && (
                <Button
                  variant="outlined"
                  loading={selectorRedux.loadingDebts}
                  disabled={
                    !values?.integration_id ||
                    handleIsValidByFilterType(
                      handleGetAppByIntegrationId(values?.integration_id)
                        ?.filter_type,
                      values
                    )
                  }
                  onClick={() => {
                    const newValues: any = { ...values };
                    dispatchRedux.updateFilters({
                      integration_id: newValues.integration_id,
                      renavam: newValues?.renavam,
                      placa: newValues?.placa,
                      document_number: newValues?.document_number,
                    });
                  }}
                >
                  Pesquisar débitos
                </Button>
              )}

              {fetchedDebts && (
                <Box display="grid" gridTemplateColumns="1fr" gap={4}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      dispatchRedux.resetDebts();

                      setValues({
                        ...initialValues,
                        renavam: '',
                      } as any);

                      document
                        .querySelector('#containerScroll')
                        ?.scrollTo(0, 0);
                    }}
                  >
                    Limpar pesquisa
                  </Button>
                  <ButtonPayActionsComponent
                    loading={selectorRedux.transactionsLoading}
                    onClickPay={() => {
                      dispatchRedux.transactionsServicePost({
                        ...values,
                      });
                    }}
                    disabledPay={
                      selectorRedux.transactionsLoading ||
                      selectorRedux.installmentSelected === null ||
                      Number((values as any).total) === 0
                    }
                    disabledSend={Number((values as any).total) === 0}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      }}
    </Formik>
  );
};

const DetranScreen = () => {
  const [modalSelectAll, setModalSelectAll] = React.useState(false);

  return (
    <PDVLayout>
      <FormDetran setModalSelectAll={setModalSelectAll} />

      <DialogSelectAllDebitsComponent
        visible={modalSelectAll}
        handleClose={() => setModalSelectAll(false)}
      />
    </PDVLayout>
  );
};

export default DetranScreen;
